// console.log(decodeURI(window.location.href));  // web 直播
import store from '@/store/state';
console.log('vuexxxxxxxxxxx', store);
const playerinfo = decodeURI((window.location.href)).split('?')[1].split(',')[0].split('&');
console.log('房间', playerinfo[0], 1);
// const url = window.location.href.split('?')[0];
let sdkappid = null;
let secretKey1 = '';
// let playerYu1 = '';
// eslint-disable-next-line eqeqeq
// if (url.indexOf('http://192.168.10.65:808') >= 0) {
//   // 公司测试
//   sdkappid = 1400619266;
//   secretKey1 = '6c52cd3d7b04c90060dfe7ce7e37750ac27522bbc6f2ff0847c79f836bc62e70';
//   playerYu1 = 'security.live.brofirst.cn';
// // eslint-disable-next-line eqeqeq
// } else if (url.indexOf('test.livepc.anqixing.com/') >= 0 || url.indexOf('http://localhost:8080/') >= 0) {
//   // 正式测试
//   sdkappid = 1400627919;
//   secretKey1 = '30b575d7700e6566ecc537d22a202be4b5375470e903e406ceb9997e2ec0adb3';
//   playerYu1 = 'test.live.anqixing.com';
//   console.log('正式测试');
// // eslint-disable-next-line eqeqeq
// } else if (url.indexOf('https://livepc.anqixing.com/') >= 0 || url.indexOf('https://securitypclive.brofirst.cn') >= 0) {
//   // 正式正式
//   sdkappid = 1400627913;
//   secretKey1 = '4452c635fa4a203f7cc0741c6433d04d7e15f84d2796a808c7d647a2b1fcf244';
//   playerYu1 = 'security.live.anqixing.com';
// }

sdkappid = 1400619266;
secretKey1 = '6c52cd3d7b04c90060dfe7ce7e37750ac27522bbc6f2ff0847c79f836bc62e70';

// let playerinfo = ''.split.call(window.location.href, '?')[1];
// console.log(playerinfo, 9999999999999);
// playerinfo = playerinfo.split(',');
// this.roomInfo.roomId = parseInt(playerinfo[0].split(',')[1]);
// this.roomInfo.roomName = decodeURI(playerinfo[1].split(',')[1]);
// this.userInfo.userId = String(playerinfo[4].split(',')[1]);
// this.userInfo.userName = decodeURI(playerinfo[5].split(',')[1]);
// this.anchorUserInfo.userId = parseInt(playerinfo[2].split(',')[1]);
// this.anchorUserInfo.userName = decodeURI(playerinfo[3].split(',')[1]);
/*
 * @Description: 这里是 TUIPlayer 应用的基础信息配置
 * @Date: 2021-10-19 16:53:28
 * @LastEditTime: 2021-11-03 10:28:09
 */

/**
 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
 *
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */
// export const sdkAppId = 1400619266;
export const sdkAppId = Number(sdkappid);
/**
* 签名过期时间，建议不要设置的过短
* <p>
* 时间单位：秒
* 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
*/
export const expireTime = 604800;

/**
* 计算签名用的加密密钥，获取步骤如下：
*
* step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
* step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
* step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
*
* 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
* 文档：https://cloud.tencent.com/document/product/647/17275#Server
*/
// export const secretKey = '6c52cd3d7b04c90060dfe7ce7e37750ac27522bbc6f2ff0847c79f836bc62e70';
export const secretKey = secretKey1;
/**
* 设置房间信息，请保证TUIPusher&TUIPlayer房间信息一致
*
*/
// console.log('sdkappid', sdkappid);
// console.log('key', secretKey1);
// console.log('房间id：', parseInt(playerinfo[0].split('=')[1]));
// console.log('房间名称：', playerinfo[1].split('=')[1]);
// console.log('用户ID：', parseInt(playerinfo[0].split('=')[1]));
// console.log('用户昵称：', parseInt(playerinfo[0].split('=')[1]));
export const roomInfo = {
  // 房间id, TUIPusher和TUIPlayer的roomId应保持一致
  roomId: parseInt(playerinfo[0].split('=')[1]),
  // 房间昵称
  roomName: decodeURI(playerinfo[1].split('=')[1]),
};

/**
* 设置推流端用户信息, 请保证TUIPusher&TUIPlayer主播信息一致
*
* 注意：web端屏幕分享流和音视频流为两个Client, 屏幕分享流用户id为`share_${userId}`
*/
export const anchorUserInfo = {
  // 用户ID
  userId: playerinfo[2].split('=')[1],
  // 用户昵称
  userName: decodeURI(playerinfo[3].split('=')[1]),
  // 用户头像
  userAvatar: '',
};

// 拉流端用户信息
export const userInfo = {
  // 用户ID
  userId: playerinfo[4].split('=')[1],
  // 用户昵称
  userName: decodeURI(playerinfo[5].split('=')[1]),
  // 用户头像
  userAvatar: '',
};
// 播放域名
// export const playerDomain = 'security.live.brofirst.cn';
export const playerDomain = 'security.live.brofirst.cn';
// console.log(playerYu1);
// export const playerDomain = playerYu1;
