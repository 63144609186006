
import axios from 'axios';
// import { getToken } from '../utils/token';
// import router from '../router/index';
// import store from '../store/index';
// console.log(window.location.href);
console.log('访问地址', window.location.href.indexOf('https://security.brofirst.cn'));
export const baseUrl = 'https://admin.anqixing.com/';
// 后期可以通过 url拼接 判断 是通过什么方式进去的  默认先写死
// if (window.location.href.indexOf('http://localhost:808') == 0 || window.location.href.indexOf('https://security.brofirst.cn') == 0) {
//   baseUrl = 'https://security.brofirst.cn';
//   console.log('本地活测试地址');
// } else if (window.location.href.indexOf('www.anqixing.com') == 0) {
//   console.log('线上地址');
//   baseUrl = 'https://admin.anqixing.com/';
// } else if (window.location.href.indexOf('https://test.pc.anqixing.com') == 0) {
//   baseUrl = 'https://test.admin.anqixing.com/';
// }
export const request = axios.create({
  baseURL: baseUrl,
  // timeout: 5000,
});

// 添加请求拦截器
request.interceptors.request.use(
  config =>
  // 在发送请求之前做些什么
  //   const token = getToken(); // 获取localstorage中的token
  //   console.log('拦截器token', token);
  //   if (token) {
  //     config.headers.token = `${token}`;  // 如果有token 就在请求头上添加token
  //   }
    config
  , error =>
  // 对请求错误做些什么
    Promise.reject(error),
);


// 添加响应拦截器
request.interceptors.response.use((res) => {
  console.log(res.data);
  // 对响应数据做点什么
  if (res.data.code == 1) {
    return res.data;
  }
  return Promise.reject(res.data.msg);
}, (error) => {
  // token过期
  if (error.response.data.code == 401) {
    // router.push(`/login?returnUrl=${encodeURIComponent(router.currentRoute.fullPath)}`);
    // store.commit('user/logOut');
    return Promise.reject('账户登录时长过期，请重新登录');
  }
  // 对响应错误做点什么
  return Promise.reject(error);
});
